import { Modal } from '@/components/base/modal';
import { Button } from '@/components/base/button';
import { PropsWithChildren } from 'react';

interface Props {
  isOpen: boolean;
  title: string;
  onCancel: () => void;
  onSave: () => void;
  cancelLabel?: string; // TODO understand defaultProps
  saveLabel?: string;
  disableSave?: boolean;
  testId: string;
}

export function Dialog({
  isOpen, title, onCancel, onSave, cancelLabel, saveLabel, children, disableSave, testId,
}: PropsWithChildren<Props>) {
  return (
    <Modal isOpen={isOpen} testId={testId}>
      <div className="text-lg font-title uppercase tracking-wide font-semibold bg-mk rounded-t-2xl p-3">{title}</div>
      {children}
      <div className="flex justify-end gap-2 p-3">
        <div>
          <button
            type="button"
            className="border border-mk-900 px-6 py-3 rounded-xl text-2xl text-mk-900 font-title"
            onClick={onCancel}
          >
            {cancelLabel}
          </button>
        </div>
        <Button label={saveLabel || Dialog.defaultProps.saveLabel} onClick={onSave} disabled={disableSave} />
      </div>
    </Modal>
  );
}

Dialog.defaultProps = {
  cancelLabel: 'Abbrechen',
  saveLabel: 'Bestätigen',
  disableSave: false,
};
