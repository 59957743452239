import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePause, faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import {
  WeekOrder, Subscription, OrderBill, Week,
} from '@/backend/api/api-service';
import { WeekHeaderConfirm } from '@/components/week/week-header-confirm';
import classNames from 'classnames';

interface Props {
  week: Week;
  order: WeekOrder | undefined;
  subscription: Subscription | undefined;
  onPause: () => void;
  onResume: () => void;
  onIClick: () => void;
  isConfirming: boolean;
  localNumberOfMeals: number;
  localOrderBill: OrderBill | null;
  confirm: () => void;
}

function createHeader(localNumberOfMeals: number, order?: WeekOrder, subscription?: Subscription) {
  const verb = order?.status === 'open' ? 'ausgewählt' : 'bestellt';

  if (!order || !subscription) {
    return undefined;
  }
  if (order.status === 'paused') {
    return 'Woche pausiert';
  }
  if (localNumberOfMeals > subscription.numberOfMeals) {
    return `${subscription.numberOfMeals} + ${localNumberOfMeals - subscription.numberOfMeals} ${verb}`;
  }
  return `${localNumberOfMeals} / ${subscription.numberOfMeals} ${verb}`;
}

export function WeekActiveHeader({
  week, order, subscription, onPause, onResume, onIClick, isConfirming, localNumberOfMeals, localOrderBill, confirm,
}: Props) {
  const [header, setHeader] = useState<string | undefined>(createHeader(localNumberOfMeals, order, subscription));

  useEffect(() => {
    setHeader(createHeader(localNumberOfMeals, order, subscription));
  }, [localNumberOfMeals, order, subscription]);

  if (week.isBlocked) {
    return (
      <div className={classNames('flex-1 text-2xl pl-4 pr-2 text-center')}>
        MARKTKOST macht Pause
      </div>
    );
  }

  if (order && order.status === 'blocked-company') {
    return (
      <div className={classNames('flex-1 text-2xl pl-4 pr-2 text-center')}>
        Dein Unternehmen macht Pause
      </div>
    );
  }

  if (order && order.status === 'blocked-user') {
    return (
      <div className={classNames('flex-1 text-2xl pl-4 pr-2 text-center')}>
        Du machst Pause
      </div>
    );
  }

  if (order && order.status === 'blocked') {
    return (
      <div className={classNames('flex-1 text-2xl pl-4 pr-2 text-center')}>
        Pause
      </div>
    );
  }

  if (order && subscription) {
    return (
      <div className="w-full">
        <div className="flex flex-wrap items-center">
          { !isConfirming
                && (
                <div>
                  {order.status === 'open' && (
                  <button type="button" onClick={onPause} className="px-3 pb-0.5 text-3xl hover:scale-110">
                    <FontAwesomeIcon icon={faCirclePause} />
                  </button>
                  )}
                  { order.status === 'paused' && (
                  <button type="button" onClick={onResume} className="px-3 pb-0.5 text-3xl hover:scale-110">
                    <FontAwesomeIcon icon={faCirclePlay} />
                  </button>
                  )}
                </div>
                )}
          <div className={classNames('flex-1 text-2xl pl-4 pr-2 text-center')}>
            {header}
          </div>
          { (isConfirming)
                && (
                <div className="pl-2">
                  <WeekHeaderConfirm bill={localOrderBill} onIClick={onIClick} confirm={confirm} />
                </div>
                )}
        </div>

      </div>
    );
  }

  if (!order) {
    return (
      <div className={classNames('flex-1 text-2xl pl-4 pr-2 text-center')}>
        Dein MARKTKOST ist nicht aktiv
      </div>
    );
  }

  return null;
}
